
.topHeaderStage{
    /* background-color: #38BE8C; */
    background-image: radial-gradient(#792E67, #441A3A, #000000, #000000); 
}

.H1{
    font-size:35px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    margin-top:0px;
    color:#FFFFFF;
    /* background-color: #38BE8C;   */
}

.H1_pc{
    font-size:55px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    line-height: 60px;
    color:#FFFFFF; 
    opacity: 0.9; 
}

.H2{
    padding-top: 15px;
    color:#FFFFFF;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.H2_pc{
    padding-top: 15px;
    color:#FFFFFF;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    margin-top: -5px;
}

.H3{
    padding-top: 5px;
    color:#FFFFFF;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;

}

.H3_mobile{
    padding-top: 5px;
    color:#FFFFFF;
    text-align: center;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    padding-bottom: 0px;
    /* max-width: 680px; */
    line-height: 25px;
    opacity: 0.95;

}

.H3_pc{
    padding-top: 5px;
    color:#FFFFFF;
    text-align: center;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    padding-bottom: 0px;
    /* max-width: 680px; */
    line-height: 25px;
    opacity: 0.95;

}

.NewStagePc_dec{
    padding:20px 150px 0px;
    font-size:38px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight:100;
    line-height: 55px;
    opacity: 0.95;
    color:#FFFFFF;
}

.NewStageMobile_dec{
    padding:10px 15px 0px;
    font-size:25px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight:400;
    /* line-height: 55px; */
    opacity: 0.80;
    color:#000000;

}

.newStageMobileWrapper{
    background-image: radial-gradient(#CDC1CC,#ECEDEA,#F4F4F4 , #F4F4F4); 
}

.resize_fit_center{
    /* max-width: 100%; */
    max-height: 100%;
    vertical-align: middle;
}

.desc_for_box{
    padding:0px 150px 0px;
    text-align: center;
    font-size: 40px;
    font-family: 'Roboto', sans-serif;
    font-weight:100;
}

.desc_for_box_sides{
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.desc_for_box_sides_children{
    flex:1;
    /* background-color: #441A3A; */
    width:100%
}

.desc_for_box_sides_carrier2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.desc_for_box_sides_carrier_text{
    font-size: 13px;
    color: #000000;
    opacity:0.95;
    font-family: 'Roboto', sans-serif;
    font-weight:200;
}

.desc_for_box_sides_carrier_h11{
    font-size: 15px;
    color: #000000;
    opacity:0.70;
    font-family: 'Roboto', sans-serif;
    font-weight:500;
}

.desc_for_box_sides_carrier{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.desc_for_box_sides_h2{
    padding:5px 0px 0px;
    text-align: center;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight:100;
}

.contactus_btn_pc{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;   
    cursor: pointer;
}

.desc_h_pc{
    color:'#000000';
    opacity:0.95;
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    padding-bottom: 15px;
}

.normal_desc_pc{
    color:'#000000';
    opacity:0.95;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    padding-bottom: 0px;
}

.contactus_btn_pc:hover{
    background-color: #FFFFFF;
}

.sub2PcH1{
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    padding-bottom: 7px;
    font-size: 25px;
    color:'#000000'
}

.sub2PcH2{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color:#000000;
    font-size: 14px;
    opacity:0.7
}

.sub2PcCircles{
    width:85px;
    height:85px;
    margin-right:0px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.sub2PcCircles_mobile{
    width:60px;
    height:60px;
    margin-right:0px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.sub2PcCircles2{
    width:80px;
    height:80px;
    margin-left:15px;
}


.buynowBtn:hover{
    background-color:#38BE8C;
}

.fade-appear{
    opacity:0;
}

.fade-appear.fade-appear-active{
    opacity: 1;
    transition: opacity 1000ms linear 300ms;
}

.fadeout-leave{
    opacity: 1;
}

.fadeout-leave.fadeout-leave-active{
    opacity: 0;
    transition: opacity 300ms linear;
}

.power_rating-carrier-m{
    /* background-color:blue; */
    /* background: url('../images/new_images/desc_bg.png'); */
    /* background-repeat: initial; */
    background-image: linear-gradient(to top,#009FF1,#202F6D);
    overflow: hidden;
    color:#FFFFFF;
}


.power_rating-carrier-pc{
    background-image: linear-gradient(to top,#009FF1,#202F6D);
    overflow: hidden;
    color:#FFFFFF;
}


.design_icon{
    background-color:#f4f4f4;
    border:2px solid #6AA75E ;
    width: 80px;
    height: 80px;
}

.design_icon2{
    width: 80px;
    height: 80px;
    background-color:#f4f4f4;
    border:2px solid #B048EB ; 
}

.design_icon3{
    background-color:#f4f4f4;
    border:2px solid #E65550 ; 
    width: 80px;
    height: 80px;
}

.footer_wrapper{
    /* background-color: lightcoral; */
    padding:'40px 0px 100px'
}

.footer_logo_holder{
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    padding:50px 20px 30px;
    height: 110px;
}

.footer_logo_carrier{
    /* background-color: ; */
    margin-top: -50px;
    opacity: 0.8;
}

.footer_h1{
    font-family: Roboto,sans-serif;
    font-size: 20px;
    font-weight: bold;
}

.footer_h2{
    font-family: Roboto,sans-serif;
    font-size: 14px;
    padding:3px 0px 3px; 
    opacity: 0.7;
}

.last_footer_wrapper{
    background-color: #F4F4F4;
    text-align: center;
    padding:20px 20px 40px;
    font-size: 13px;
}

.last_footer_wrapper_pc{
    background-color: #F4F4F4;
    text-align: center;
    padding:20px 20px 80px;
    font-size: 13px;
}

.testimony_wrapper_m{
    background-image: linear-gradient(to top,#FBFBFD,#E9EAE5);
    padding:15px 0px 30px;
}

.testimony_wrapper_pc{
    background-image: linear-gradient(to top,#FBFBFD,#E9EAE5);
    padding:15px 15px 30px;
}

.award_wrapper_pc{
    /* background-image: linear-gradient(to top,#76461B,#E9EAE5); */
    background:url('../images/new_images/award_bg.jpeg') no-repeat center center fixed;
    padding:30px 15px 50px;
    margin:0px auto -20px;
    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color:'#FFFFFF';
}


.bubble_profile_carrier{
    position: absolute;
    margin-top: -30px;
    display: flex;
    flex-direction: row;
    /* background-color: moccasin; */
    justify-content: flex-start;
    align-items: flex-start;
}

.bubble_profile_wrapper{
    width:50px;
    height:50px;
    border-radius: 60px;
    background-color: #FBFBFA;
    /* background-color: rgba(255,255,255,0.99); */
    /* border:1px solid #F7F7F8; */
}

.bubble_profile_name{
    color:#000000;
    padding: 5px 0px 0px 5px;
    font-size: 14px;
    font-weight: 400;
    font-family: Roboto,sans-serif;
}

.testimony_wrapper{
    padding: 30px 10px 30px;
    position: relative;
    /* background-color: moccasin; */
}

.testimony_wrapper{
    padding: 30px 10px 30px;
    position: relative;
    /* background-color: moccasin; */
}

.testimony_wrapper_bubble_pc{
    flex:1;
    padding: 0px 10px 0px;
}

.bubble_mgs_wrapper{
    background-color: rgba(255,255,255,0.75);
    margin:0px 0px 0px 25px;
    padding: 10px 10px 15px;
    font-size: 13px;
    color: #212121;
    text-indent: 15px;
    border-radius: 10px;
    border:1px solid #E9EAE5;
    text-align: left;
}


/* power for all styles */


.power_for_all_header_wrapper_mobile{
    /* background-image: linear-gradient(to top,#76461B,#E9EAE5); */
    background:url('../images/new_images/blackbaby.jpg') no-repeat top center fixed;
    padding:0px;
    margin:0px auto 0px;
    -webkit-background-size: auto;
    -moz-background-size: auto;
    -o-background-size: auto;
    background-size: auto; 
    color:'#FFFFFF';
}

.powerforall_H2{
    font-size:40px;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    color:#FFFFFF;  
}


.powerforall_H3{
    font-size:20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    color:#FFFFFF;  
}


.footer_logo_holder_powerforall{
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    padding:20px 20px 20px;
    height: 50px;
}

.footer_logo_carrier_powerforall{
    /* background-color: ; */
    margin-top: -50px;
    opacity: 0.8;
}